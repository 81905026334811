import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Self,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatestWith, firstValueFrom, Subject, takeUntil } from 'rxjs';
import { DestroyableComponent } from '../destroyable.component';
import { format, isDate, isToday, isTomorrow, isValid } from 'date-fns';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as chrono from 'chrono-node';
import { formatDate } from '@angular/common';
import { DateTime } from 'luxon';
import { filter } from 'rxjs/operators';
import { getUserShortTimezone } from '../../utilities/date';
import { MatFormFieldControl } from '@angular/material/form-field';

const defaultDisplayValue = '';
const defaultFormat = 'MM/dd/yyyy hh:mm aa';

@Component({
  selector: 'tb-text-to-date',
  templateUrl: './text-to-date.component.html',
  styles: [
    `
      input {
        border: none;
        background: none;
        padding: 0;
        outline: none;
        font: inherit;
        color: currentColor;
      }
    `,
  ],
  providers: [{ provide: MatFormFieldControl, useExisting: TextToDateComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextToDateComponent
  extends DestroyableComponent
  implements ControlValueAccessor, MatFormFieldControl<Date>, OnDestroy
{
  // matInput setup
  public static nextId = 0;
  @HostBinding() public id = `tb-text-to-date-${TextToDateComponent.nextId++}`;
  public touched = false;
  public focused = false;

  public onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  public onFocusOut(event: FocusEvent) {
    if (!this.selfRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  private _placeholder: string;
  @Input()
  public get placeholder() {
    return this._placeholder;
  }

  public set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  // eslint-disable-next-line rxjs/finnish,rxjs/no-exposed-subjects
  public stateChanges = new Subject<void>();

  public get empty() {
    return !this.fc.value?.length;
  }

  public get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  private _required = false;
  @Input()
  public get required() {
    const b = this._required || this.ngControl?.control?.hasValidator(Validators.required);
    return b;
  }

  public set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  private _disabled = false;
  @Input()
  public get disabled(): boolean {
    return this._disabled;
  }

  public set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.fc.disable() : this.fc.enable();
    this.stateChanges.next();
  }

  public get errorState(): boolean {
    return this.fc.invalid && this.touched;
  }

  public controlType = 'tb-text-to-date';

  public get value(): Date {
    return this.currentDateValue;
  }

  public set value(date: Date) {
    this.fc.setValue(format(date, defaultFormat));
    this.displayValue$$.next(this.formatDateStandard(date));
    this.stateChanges.next();
  }

  public setDescribedByIds(ids: string[]) {
    const controlElement = this.selfRef.nativeElement.querySelector('.tb-text-to-date')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  public onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.selfRef.nativeElement.querySelector('input').focus();
    }
  }

  public autofilled?: boolean;

  // component setup
  public fc = new FormControl<string | null>(null);
  private displayValue$$ = new BehaviorSubject<string>(defaultDisplayValue);
  public displayValue$ = this.displayValue$$.asObservable();
  private currentDateValue: Date | null;
  private onTouched = () => {};
  private onChange = (date) => {};
  private _timezoneName$$ = new BehaviorSubject<string | null>(null);

  @Input()
  public set timezoneName(name: string | null) {
    this._timezoneName$$.next(name);
  }

  public get timezoneName(): string | null {
    return this._timezoneName$$.value || getUserShortTimezone();
  }

  private _timezoneDisplayName$$ = new BehaviorSubject<string | null>(null);
  @Input()
  public set timezoneDisplayName(name: string | null) {
    this._timezoneDisplayName$$.next(name);
  }

  public get timezoneDisplayName(): string | null {
    return this._timezoneDisplayName$$.value || getUserShortTimezone();
  }

  @Input() public doTimeZoneConversion = true;

  constructor(@Optional() @Self() public ngControl: NgControl, private selfRef: ElementRef) {
    super();
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
    this.listenToTyping();
  }

  public override ngOnDestroy() {
    super.ngOnDestroy();
    this.stateChanges.complete();
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(obj: any): void {
    if (!obj) {
      return;
    }
    const asDate = new Date(obj);
    if (isValid(asDate)) {
      this.writeValueWithTimezone(asDate);
    }
  }

  private async writeValueWithTimezone(date: Date) {
    const timezoneName = await firstValueFrom(this._timezoneName$$.pipe(filter((n) => !!n)));
    const inCorrectTZ = DateTime.fromJSDate(date)
      .setZone(timezoneName)
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone, { keepLocalTime: true });
    this.value = inCorrectTZ.toJSDate();
  }

  public setDate(date: Date) {
    const inCorrectTZ = DateTime.fromJSDate(date).setZone(this.timezoneName, { keepLocalTime: true });
    this.onChange(this.doTimeZoneConversion ? inCorrectTZ.toJSDate() : date);
    this.currentDateValue = this.doTimeZoneConversion ? inCorrectTZ.toJSDate() : date;
    this.displayValue$$.next(this.formatDateStandard(date));
    this.stateChanges.next();
  }

  public listenToTyping() {
    this.fc.valueChanges.pipe(combineLatestWith(this._timezoneName$$), takeUntil(this.destroy$$)).subscribe({
      next: ([text, _]: [string | null, string | null]) => {
        if (!text) {
          this.onChange(null);
          this.displayValue$$.next(defaultDisplayValue);
          return;
        }
        const date = chrono.parseDate(text, new Date(), { forwardDate: true });
        if (isValid(date)) {
          this.setDate(date);
        }
      },
    });
  }

  private standardDateFormat(value: any): string {
    const timezone = this.timezoneDisplayName
      ? `'${this.timezoneDisplayName}'`
      : this.timezoneName
      ? `'${this.timezoneName}'`
      : '';
    const asDate = new Date(value);
    let formattingText = `h:mmaaaaa'm' ${timezone} M/dd/yyyy`;
    if (isToday(asDate)) {
      formattingText = `h:mmaaaaa'm' ${timezone} 'Today'`;
    } else if (isTomorrow(asDate)) {
      formattingText = `h:mmaaaaa'm' ${timezone} 'Tomorrow'`;
    }
    return formattingText;
  }

  private formatDateStandard(value: any): string {
    if (!isDate(value)) {
      value = new Date(value);
    }
    const formatStr = this.standardDateFormat(value);
    return formatDate(value, formatStr, 'en-US');
  }
}

export const stringOrDateToDate = (date: string | Date) => {
  if (typeof date === 'string') {
    return new Date(date);
  }
  return date;
};

export const getUserShortTimezone = (): string | undefined => {
  return /.*\s(.+)/.exec(new Date().toLocaleDateString(navigator.language, { timeZoneName: 'short' }))?.[1];
};

export const stringOrDateToDateISO = (date: string | Date) => {
  if (date === '' || date === null || date === undefined) {
    return null;
  }
  if (typeof date === 'string') {
    return new Date(date).toISOString();
  }
  return date.toISOString();
};

export const removePluralIfNeeded = (count: number, pluralString: string) => {
  if (count !== 1 || !pluralString.endsWith('s')) {
    return pluralString;
  }
  return pluralString.substring(0, pluralString.length - 1);
};

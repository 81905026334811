import { ChangeDetectionStrategy, Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, catchError, combineLatestWith, debounceTime, Observable, of, Subject, switchMap } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { LohiLoadStatus, VoogleSearchResult } from '../types';
import { shareReplayComponentConfig } from '../constants';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'tb-voogle',
  templateUrl: './voogle-search.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VoogleSearchComponent {
  public searchBox = new FormControl<string>(null);
  private loading$$ = new BehaviorSubject<boolean>(false);
  private searchText$$ = new Subject<string>();
  public searchResults$: Observable<VoogleSearchResult[]>;
  private searchResults$$ = new BehaviorSubject<VoogleSearchResult[]>([]);
  public dropdownOpen = false;

  private maxLoadsToShow$$ = new BehaviorSubject<number>(10);
  public maxLoadsToShow$ = this.maxLoadsToShow$$.pipe(shareReplay(shareReplayComponentConfig));
  public maxLoadSearchResults$: Observable<{
    results: VoogleSearchResult[];
    showMore: boolean;
  }>;
  public active$: Observable<boolean>;

  constructor(private http: HttpClient) {
    // The searchResults$ is now tied directly to the search results observable from the API response
    this.searchResults$ = this.searchResults$$.asObservable();
    this.active$ = this.loading$$.asObservable();

    this.maxLoadSearchResults$ = this.searchResults$.pipe(
      combineLatestWith(this.maxLoadsToShow$),
      map(([results, max]) => {
        return {
          results: results.slice(0, max),
          showMore: results.length > max,
        };
      }),
    );

    this.searchBox.valueChanges.subscribe((value) => this.search(value));
    this.listenToSearchTextChange();
  }

  public showMoreLoads() {
    this.maxLoadsToShow$$.next(this.maxLoadsToShow$$.value + 5);
  }

  public openDropdown() {
    this.dropdownOpen = true;
  }

  public closeDropdown() {
    this.dropdownOpen = false;
  }

  @HostListener('document:click', ['$event.target'])
  public onClickOutside(target: HTMLElement) {
    const clickedInside = target.closest('.relative');
    if (!clickedInside) {
      this.closeDropdown();
    }
  }

  public handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeDropdown();
    }
  }

  public search(text: string) {
    this.searchText$$.next(text);
  }

  private listenToSearchTextChange() {
    this.searchText$$
      .pipe(debounceTime(50))
      .pipe(
        switchMap((searchText) => {
          this.loading$$.next(true);
          if (searchText) {
            return this.http
              .get<{ results: VoogleSearchResult[] }>(
                `${environment.api}/v1/external/broker_portal/vst/voogle/search`,
                {
                  params: { q: searchText },
                },
              )
              .pipe(catchError(() => of({ results: [] })));
          } else {
            return of({ results: [] });
          }
        }),
      )
      .subscribe((results) => {
        this.loading$$.next(false);
        this.searchResults$$.next(results?.results || []);
      });
  }

  public getRouterLink(res: VoogleSearchResult): string[] {
    switch (res.type) {
      case 'Shipment':
        return ['/vst/loads', res.id];
      case 'Trailer':
        return ['/vst/asset_dashboard/trailer', res.id];
      case 'Facility':
        return ['/vst/facility', res.id];
      default:
        return ['/'];
    }
  }

  protected readonly document = document;
  protected readonly loadStatuses = LohiLoadStatus;
}

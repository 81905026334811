<mat-form-field *ngIf="!selectSearch" class="w-full">
  <mat-icon matPrefix>search</mat-icon>
  <mat-label>{{ placeholder }}</mat-label>
  <input matInput autocomplete="off" [formControl]="searchControl" />
</mat-form-field>
<ng-container *ngIf="selectSearch">
  <mat-option>
    <ngx-mat-select-search placeholderLabel="Search" [formControl]="searchControl"></ngx-mat-select-search>
  </mat-option>
</ng-container>

import { ChangeDetectionStrategy, Component, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { AuthService, BrokerUserInfo } from '../services/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LoadSearchComponent } from '../../modules/vst/vst-loads/load-search/load-search.component';
import { ShipmentExportComponent } from '../components/shipment-export/shipment-export.component';
import * as LogRocket from 'logrocket';

@Component({
  selector: 'tb-side-nav',
  templateUrl: './side-nav.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavComponent {
  @ViewChild('uploadResult') public uploadResultTemplate: TemplateRef<any>;
  public userInfo$: Observable<BrokerUserInfo>;

  constructor(private authService: AuthService, private matDialog: MatDialog) {
    this.userInfo$ = this.authService.userInfo$;
  }

  public logout() {
    this.authService.logout();
  }

  @HostListener('document:keydown.control.enter', ['$event'])
  public openLoadSearchEnter($event: KeyboardEvent) {
    $event.preventDefault();
    this.openLoadSearch();
  }

  @HostListener('document:keydown.control.k', ['$event'])
  public openLoadSearchK($event: KeyboardEvent) {
    $event.preventDefault();
    this.openLoadSearch();
  }

  public openLoadSearch() {
    if (LogRocket) {
      LogRocket.track('searchOpened');
    }
    this.matDialog.open(LoadSearchComponent, {
      panelClass: ['w-full', 'md:w-1/2', 'lg:w-1/3', 'no-padding-dialog'],
    });
  }

  public downloadShipmentExport() {
    this.matDialog.open(ShipmentExportComponent);
  }
}

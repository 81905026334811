import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SimpleFinn } from '../../shared/utilities/simpleFinn';
import {
  BehaviorSubject,
  catchError,
  combineLatestWith,
  debounceTime,
  firstValueFrom,
  lastValueFrom,
  Observable,
  of,
  Subject,
  switchMap,
} from 'rxjs';
import { environment } from '../../../environments/environment';
import { map, pluck, shareReplay, tap } from 'rxjs/operators';
import { RouterStateService } from '../../shared/services/router-state.service';
import { LOHI_LOAD_ID } from './loads.constants';
import {
  Accessorial,
  Bid,
  BrokerLoad,
  BrokerQuoteRequest,
  CustomChargeType,
  ExportUpload,
  LoadChargeDetails,
  LohiLoad,
  PricingReport,
  SlimLohiLoad,
} from './types';
import { RouterFinn } from '../../shared/utilities/routerFinn';
import { once, sort } from 'remeda';
import { compareAsc, compareDesc, format, parse } from 'date-fns';
import { Equipment, LongLat, Unit } from '../../shared/types';

const FACILITY_IDS_FILTER = 'FACILITY_IDS_FILTER';

export interface Page<T> {
  content: T[];
  pageNumber: number;
  pageSize: number;
  totalRows: number;
  totalPages: number;
}

export interface MissingFacilityPair {
  destinationSiteId: string;
  destinationSiteName: string;
  originSiteId: string;
  originSiteName: string;
}

export interface FoundFacilityPair extends MissingFacilityPair {
  billableMiles: number;
}

export interface DrayageExportUploadResult {
  markNumber: string;
  message: string;
}

export interface LoadHistoryMap {
  details: LoadHistoryMapDetails;
  waypoints: LoadHistoryMapWaypoint[];
  stops: LoadHistoryMapStop[];
}

export interface LoadHistoryMapDetails {
  id: string;
  status: string;
  assignedAt: string;
  startedAt: string;
  completedAt: string;
  completedByName: string;
  driverPhone: string;
  driverName: string;
  earliestReceiptDate: string;
  portCutoffDate: string;
  rampCutoffTime: string;
}

export interface LoadHistoryMapWaypoint {
  lngLat: LongLat;
  clientCreatedAt: string;
  accuracy: number;
  speed: number;
  createdAt: string;
}

export interface LoadHistoryMapStop {
  sequence: number;
  type: string;
  facilityId: string;
  name: string;
  arrivedAt: string;
  exitedAt: string;
  lngLat: LongLat;
  completedAt: string;
  manuallyCompleted: boolean;
  geofenceRadiusMeters: number;
  timezone: string;
}

interface MonthOption {
  value: string;
  loading: boolean;
  loaded: boolean;
}

export interface AgLoadListItem {
  bookingNumber?: string;
  loadId: string;
  customerLoadId: string;
  steamshipLine: string;
  vesselName: string;
  voyageNumber: string;
  loadingFacility: string;
  loadingLocation: string;
  destinationFacility: string;
  destinationLocation: string;
  lane: string;
  miles: number;
  createdDate: string;
  loadingDate: string;
  readyDate: string;
  erd: string;
  docCut: string;
  rampPortCut: string;
  status: string;
  calculatedStatus: string;
  carrierAssigned: string;
  containerNumber: string;
  sealNumber: string;
  loadedDate: string;
  completedDate?: string;
  customerRateCents: number; // this needs to be a string to export to excel correctly
  quantity: number;
  weight: number;
  commodity: string;
  activeRatecon: boolean;
  brokerageName: string;
}

export interface AgLoadListItemAPI {
  bookingNumber: string;
  loadId: string;
  customerLoadId: string;
  steamshipLine: string;
  vesselName: string;
  voyageNumber: string;
  loadingFacility: string;
  loadingLocation: string;
  destinationFacility: string;
  destinationLocation: string;
  lane: string;
  miles: number;
  createdDate: string;
  loadingDate: string;
  readyDate: string;
  erd: string;
  docCut: string;
  rampPortCut: string;
  status: string;
  calculatedStatus: string;
  carrierAssigned: string;
  containerNumber: string;
  sealNumber: string;
  loadedDate: string;
  completedDate: string;
  customerRateCents: number;
  quantity: number;
  weight: number;
  commodity: string;
  activeRatecon: boolean;
  brokerageName: string;
}

export const yearMonthFormat = 'MMM yyyy';

@Injectable({
  providedIn: 'root',
})
export class LoadService {
  private loadsPage$$ = new Subject<{
    pageNumber: number;
    pageSize: number;
  }>();
  private loadsSearchText$$ = new BehaviorSubject<string>(null);
  private facilityIDsFilter$$ = new BehaviorSubject<string[]>([]);
  private poolIDsFilter$$ = new BehaviorSubject<number[]>([]);

  private unassignedLoads$$ = new BehaviorSubject<Page<SlimLohiLoad>>(null);
  public unassignedLoads$ = this.unassignedLoads$$.pipe(shareReplay(1));
  private unassignedLoadsLoading$$ = new BehaviorSubject<boolean>(false);
  public unassignedLoadsLoading$ = this.unassignedLoadsLoading$$.pipe(shareReplay(1));

  private coveredLoads$$ = new BehaviorSubject<Page<SlimLohiLoad>>(null);
  public coveredLoads$ = this.coveredLoads$$.pipe(shareReplay(1));
  private coveredLoadsLoading$$ = new BehaviorSubject<boolean>(false);
  public coveredLoadsLoading$ = this.coveredLoadsLoading$$.pipe(shareReplay(1));

  private inProgressLoads$$ = new BehaviorSubject<Page<SlimLohiLoad>>(null);
  public inProgressLoads$ = this.inProgressLoads$$.pipe(shareReplay(1));
  private inProgressLoadsLoading$$ = new BehaviorSubject<boolean>(false);
  public inProgressLoadsLoading$ = this.inProgressLoadsLoading$$.pipe(shareReplay(1));

  private completedLoads$$ = new BehaviorSubject<Page<SlimLohiLoad>>(null);
  public completedLoads$ = this.completedLoads$$.pipe(shareReplay(1));
  private completedLoadsLoading$$ = new BehaviorSubject<boolean>(false);
  public completedLoadsLoading$ = this.completedLoadsLoading$$.pipe(shareReplay(1));
  private monthOptions$$ = new BehaviorSubject<MonthOption[]>(null);

  public get monthOptions$(): Observable<MonthOption[]> {
    this.getMonthSelections();
    return this.monthOptions$$.asObservable();
  }

  private agLoads$$ = new BehaviorSubject<AgLoadListItem[]>([]);
  public agLoads$ = this.agLoads$$.asObservable();

  private lohiLoad$$ = new BehaviorSubject<LohiLoad>(null);
  public lohiLoad$: Observable<LohiLoad> = this.lohiLoad$$.pipe(shareReplay(1));

  private loadHistoryMap$$ = new BehaviorSubject<LoadHistoryMap>(null);
  public loadHistoryMap$: Observable<LoadHistoryMap> = this.loadHistoryMap$$.pipe(shareReplay(1));

  private quotes$$ = new BehaviorSubject<Page<BrokerQuoteRequest>>(null);
  public quotes$: Observable<Page<BrokerQuoteRequest>> = this.quotes$$.pipe(shareReplay(1));

  private quotesPage$$ = new BehaviorSubject<{ pageNumber: number; pageSize: number }>({
    pageNumber: 0,
    pageSize: 100,
  });

  constructor(private http: HttpClient, private routerState: RouterStateService) {
    this.quotesPage$$
      .pipe(switchMap(({ pageNumber, pageSize }) => this.loadQuotes(pageNumber, pageSize)))
      .subscribe((page) => this.quotes$$.next(page));

    this.facilityIDsFilter$$.next(this.facilityIDsFilterFromLocalStorage());
    this.facilityIDsFilter$$.subscribe((facilityIDs) => this.facilityIDsFilterToLocalStorage(facilityIDs));

    const loadParams$ = this.loadsPage$$.pipe(
      combineLatestWith(this.loadsSearchText$$, this.facilityIDsFilter$$, this.poolIDsFilter$$),
      debounceTime(300),
      shareReplay(1),
    );
    loadParams$
      .pipe(
        tap(() => this.completedLoadsLoading$$.next(true)),
        switchMap(([page, search, facilityIDs, poolIDs]) =>
          this.loadLohiLoadsByStatus(page.pageNumber, page.pageSize, ['completed'], search, facilityIDs, poolIDs),
        ),
        map((value) => {
          return {
            ...value,
            content: sort(value.content, (a, b) => compareDesc(new Date(a.completedAt), new Date(a.completedAt))),
          };
        }),
        tap(() => this.completedLoadsLoading$$.next(false)),
      )
      .subscribe((page) => this.completedLoads$$.next(page));
    loadParams$
      .pipe(
        tap(() => this.unassignedLoadsLoading$$.next(true)),
        switchMap(([page, search, facilityIDs, poolIDs]) =>
          this.loadLohiLoadsByStatus(page.pageNumber, page.pageSize, ['not_assigned'], search, facilityIDs, poolIDs),
        ),
        tap(() => this.unassignedLoadsLoading$$.next(false)),
      )
      .subscribe((page) => this.unassignedLoads$$.next(page));
    loadParams$
      .pipe(
        tap(() => this.coveredLoadsLoading$$.next(true)),
        switchMap(([page, search, facilityIDs, poolIDs]) =>
          this.loadLohiLoadsByStatus(page.pageNumber, page.pageSize, ['pending'], search, facilityIDs, poolIDs),
        ),
        tap(() => this.coveredLoadsLoading$$.next(false)),
      )
      .subscribe((page) => this.coveredLoads$$.next(page));
    loadParams$
      .pipe(
        tap(() => this.inProgressLoadsLoading$$.next(true)),
        switchMap(([page, search, facilityIDs, poolIDs]) =>
          this.loadLohiLoadsByStatus(page.pageNumber, page.pageSize, ['in_progress'], search, facilityIDs, poolIDs),
        ),
        tap(() => this.inProgressLoadsLoading$$.next(false)),
      )
      .subscribe((page) => this.inProgressLoads$$.next(page));
    this.listenForLohiLoad();
  }

  public changeLoadPage(pageNumber: number, pageSize: number) {
    this.loadsPage$$.next({
      pageNumber,
      pageSize,
    });
  }

  public changeLoadSearchText(search: string) {
    this.loadsSearchText$$.next(search);
  }

  public changeFacilityIDsFilter(facilityIDs: string[]) {
    this.facilityIDsFilter$$.next(facilityIDs);
  }

  public getFacilityIDsFilter() {
    return this.facilityIDsFilter$$.value;
  }

  public changePoolIDsFilter(poolIDs: number[]) {
    this.poolIDsFilter$$.next(poolIDs);
  }

  private loadLohiLoadsByStatus = (
    pageNumber: number,
    pageSize: number,
    status: string[],
    searchText: string,
    facilityIDs: string[],
    poolIDs: number[],
  ): Promise<Page<SlimLohiLoad>> => {
    return lastValueFrom(
      this.http
        .get<Page<SlimLohiLoad>>(`${environment.api}/v1/external/broker_portal/loads/lohi_status`, {
          params: {
            pageNumber,
            pageSize,
            status,
            searchText: searchText || '',
            facilityIDs,
            poolIDs,
          },
        })
        .pipe(
          map((response) => {
            response.content = response.content || [];
            response.content.forEach((load) => {
              load.pickupStop = load.Stops?.find((stop) => stop.type == 'pickup');
              load.dropoffStop = load.Stops?.find((stop) => stop.type == 'dropoff');
            });
            return response;
          }),
        ),
    );
  };

  private getMonthSelections = once(() => {
    this.http
      .get<{ months: string[] }>(`${environment.api}/v1/external/broker_portal/loads/load_list_month_options`)
      .subscribe((response) => {
        this.mergeMonthsOptions(
          (response.months || []).map((month) => ({
            value: month,
          })),
        );
      });
  });

  public loadListByMonth = (yearMonth: string) => {
    if (this.isLoadedOrLoading(yearMonth)) {
      return;
    }
    this.mergeMonthsOptions([
      {
        value: yearMonth,
        loading: true,
      },
    ]);
    this.http
      .get<{
        dateLoaded: string;
        loads: AgLoadListItemAPI[];
      }>(`${environment.api}/v1/external/broker_portal/loads/load_list_by_month`, {
        params: {
          yearMonth,
        },
      })
      .subscribe((response) => {
        this.mergeMonthsOptions([
          {
            value: response.dateLoaded,
            loaded: true,
            loading: false,
          },
        ]);
        this.agLoads$$.next([...this.agLoads$$.value, ...response.loads.map(fromApiLoadToLoad)] || []);
      });
  };

  private isLoadedOrLoading = (month: string) => {
    if (!this.monthOptions$$.value?.length) {
      return false;
    }
    const option = this.monthOptions$$.value.find((option) => option.value === month);
    return option?.loaded || option?.loading;
  };

  private mergeMonthsOptions(monthOptions: (Partial<MonthOption> & { value: string })[]) {
    let needsSort = false;
    const currentOptions = [...(this.monthOptions$$.value || [])];
    const base: Omit<MonthOption, 'value'> = {
      loaded: false,
      loading: false,
    };
    monthOptions.forEach((monthOption) => {
      let replacementIndex = currentOptions.findIndex((option) => option.value === monthOption.value);
      if (replacementIndex === -1) {
        currentOptions.push({
          ...base,
          ...monthOption,
        });
        needsSort = true;
      } else {
        currentOptions[replacementIndex] = {
          ...currentOptions[replacementIndex],
          ...monthOption,
        };
      }
    });
    if (needsSort) {
      currentOptions.sort((a, b) => {
        const aDate = parse(a.value, yearMonthFormat, new Date());
        const bDate = parse(b.value, yearMonthFormat, new Date());
        return compareDesc(aDate, bDate);
      });
    }
    this.monthOptions$$.next(currentOptions);
  }

  private listenForLohiLoad() {
    this.routerState
      .listenForParamChange$(LOHI_LOAD_ID)
      .pipe(
        tap((loadID) => {
          if (!loadID) {
            this.lohiLoad$$.next(null);
          }
        }),
        switchMap((loadID) => {
          if (!loadID) {
            return of(null);
          }
          if (loadID === 'other') {
            return of(null);
          }
          return this.getLohiLoad(loadID);
        }),
      )
      .subscribe((load) => {
        this.lohiLoad$$.next(load);
        if (load) {
          this.refreshLohiLoadMap(load.id);
        }
      });
  }

  private getLohiLoad = (loadID: string): Observable<LohiLoad> => {
    return this.http.get<LohiLoad>(`${environment.api}/v1/external/broker_portal/loads/lohi`, {
      params: {
        loadID,
      },
    });
  };

  private getLoadHistoryMap = (loadID: string): Observable<LoadHistoryMap> => {
    return this.http.get<LoadHistoryMap>(`${environment.api}/v1/external/broker_portal/loads/load_history_map`, {
      params: {
        loadID,
      },
    });
  };

  private async refreshLohiLoadMap(loadID: string) {
    const map = await lastValueFrom(this.getLoadHistoryMap(loadID));
    this.loadHistoryMap$$.next(map);
  }

  public async uploadQuotes(loads: Array<any>, useDatMedian: boolean): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v1/external/broker_portal/quotes/upload`, {
          loads,
          useDatMedian,
        }),
      );
      const page = await firstValueFrom(this.quotesPage$$);
      this.quotesPage$$.next({ pageNumber: 0, pageSize: page.pageSize });
      return true;
    } catch (e) {
      throw e;
    }
  }

  public changeQuotesPage(pageNumber: number, pageSize: number) {
    this.quotesPage$$.next({ pageNumber, pageSize });
  }

  private loadQuotes = (pageNumber: number, pageSize: number): Promise<Page<BrokerQuoteRequest>> => {
    return lastValueFrom(
      this.http
        .get<Page<BrokerQuoteRequest>>(`${environment.api}/v1/external/broker_portal/quotes`, {
          params: {
            pageNumber,
            pageSize,
          },
        })
        .pipe(
          map((page) => {
            page.content = page.content || [];
            return page;
          }),
        ),
    );
  };

  public getBid = (value: any): Observable<Bid> => {
    return this.http.post<Bid>(`${environment.api}/v2/external/shipper_load_bid/broker_bid`, value);
  };

  public getExport = (): Observable<string> =>
    this.http
      .get<{ url: string }>(`${environment.api}/v1/external/broker_portal/quotes/export`)
      .pipe(map((value) => value.url));

  public async uploadShipments(
    loads: Array<ExportUpload>,
  ): Promise<{ success: string[]; failed: Record<string, string> }> {
    try {
      return await lastValueFrom(
        this.http.post<{ success: string[]; failed: Record<string, string> }>(
          `${environment.api}/v1/external/broker_portal/loads/upload_2`,
          {
            loads,
            offsetMinutes: new Date().getTimezoneOffset(),
          },
        ),
      );
    } catch (e) {
      throw e;
    }
  }

  public async uploadDrayageExports(loads: Array<any>): Promise<{
    successes: DrayageExportUploadResult[];
    failures: DrayageExportUploadResult[];
  }> {
    try {
      return await lastValueFrom(
        this.http.post<{
          successes: DrayageExportUploadResult[];
          failures: DrayageExportUploadResult[];
        }>(`${environment.api}/v1/external/broker_portal/loads/upload_exports`, {
          loads,
          offsetMinutes: new Date().getTimezoneOffset(),
        }),
      );
    } catch (e) {
      throw e;
    }
  }

  // this may not belong here but didn't want to cross module here so I made a call to do this
  public async upsertBillableMiles(originId: string, destinationId: string, billableMiles: number): Promise<number> {
    try {
      const response = await lastValueFrom(
        this.http.post<{
          billableMiles: {
            id: number;
            createdBy: string;
            originId: string;
            destinationId: string;
            billableMiles: number;
            createdAt: string;
            notes: string | null;
          };
        }>(`${environment.api}/v1/external/broker_portal/facilities/vpf_billable_miles`, {
          originId,
          destinationId,
          billableMiles,
        }),
      );
      return response.billableMiles.billableMiles;
    } catch (e) {
      throw e;
    }
  }

  public async uploadShipmentsPrecheck(loads: Array<any>): Promise<{
    missingFacilities: string[];
    missingFacilityPairs: MissingFacilityPair[];
    foundFacilityPairs: FoundFacilityPair[];
  }> {
    try {
      return await lastValueFrom(
        this.http.post<{
          missingFacilities: string[];
          missingFacilityPairs: MissingFacilityPair[];
          foundFacilityPairs: FoundFacilityPair[];
        }>(`${environment.api}/v1/external/broker_portal/loads/upload_2_precheck`, {
          loads,
        }),
      );
    } catch (e) {
      throw e;
    }
  }

  public getShipmentExport = async (startAt: string, endAt: string): Promise<string> => {
    try {
      const exportURL = await lastValueFrom(
        this.http.get<{ url: string }>(`${environment.api}/v1/external/broker_portal/loads/export_loads`, {
          params: {
            startAt,
            endAt,
          },
        }),
      );
      return exportURL.url;
    } catch (e) {
      return null;
    }
  };

  public async bookBackhaul(formValue: any) {
    return lastValueFrom(
      this.http.post<LohiLoad>(`${environment.api}/v1/external/broker_portal/backhauls/accept`, formValue),
    );
  }

  private facilityIDsFilterFromLocalStorage(): string[] {
    const rawVal = localStorage.getItem(FACILITY_IDS_FILTER);
    return rawVal ? (JSON.parse(rawVal) as string[]) : [];
  }

  private facilityIDsFilterToLocalStorage(facilityIDs: string[]): void {
    localStorage.setItem(FACILITY_IDS_FILTER, JSON.stringify(facilityIDs));
  }

  public async uploadStops(customerId: string, loads: any) {
    try {
      const errors = await lastValueFrom(
        this.http.post<{ errorList: string[] }>(
          `${environment.api}/v1/external/broker_portal/loads/update_arrival_windows`,
          {
            customerId,
            loads,
          },
        ),
      );
      return errors?.errorList ?? [];
    } catch (e) {
      return false;
    }
  }

  public async markPriority(loadId: string, isPriority: boolean) {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v1/external/broker_portal/loads/mark_load_as_priority`, {
          loadId,
          isPriority,
        }),
      );
      return true;
    } catch (e) {
      return false;
    }
  }
}

function fromApiLoadToLoad(load: AgLoadListItemAPI): AgLoadListItem {
  return {
    ...load,
    customerRateCents: load.customerRateCents / 100,
  };
}

<div class="relative">
  <div
    class="flex h-[40px] items-center w-[800px] max-w-full rounded-full border border-gray-300 dark:border-gray-600 bg-transparent focus-within:border-blue-500 focus-within:ring focus-within:ring-blue-300 transition"
  >
    <input
      #searchInput
      type="text"
      class="flex-1 bg-transparent placeholder-gray-600 dark:placeholder-gray-400 text-gray-900 dark:text-gray-200 text-center focus:outline-none focus:ring-0"
      [placeholder]="searchInput === document.activeElement ? '' : 'Search for Shipments, Facilities, and More!'"
      autocomplete="off"
      [formControl]="searchBox"
      (focus)="openDropdown()"
      (blur)="searchInput.blur()"
      (keydown)="handleKeydown($event)"
    />
    <mat-progress-spinner
      *ngIf="active$ | async"
      mode="indeterminate"
      diameter="20"
      class="pr-3"
    ></mat-progress-spinner>
  </div>

  <div *ngIf="dropdownOpen">
    <ng-container *ngIf="{ results: maxLoadSearchResults$ | async } as searchResults">
      <div
        *ngIf="searchResults.results?.results?.length"
        class="absolute w-[800px] bg-white/75 dark:bg-zinc-900/75 shadow-lg border border-gray-200 dark:border-zinc-700 z-50 max-h-96 overflow-y-auto mt-1 rounded-lg backdrop-blur-sm"
        (click)="$event.stopPropagation()"
      >
        <a
          *ngFor="
            let res of searchResults.results?.results;
            trackBy: searchResults.results?.results | trackByKey : 'id'
          "
          class="flex flex-col cursor-pointer p-3 hover:dark:bg-zinc-700 hover:bg-gray-200 color-transition rounded-md"
          (click)="closeDropdown()"
          [routerLink]="getRouterLink(res)"
        >
          <div class="flex mb-2">
            <span
              *ngIf="res.type === 'Shipment'; else nonShip"
              class="text-left font-semibold text-gray-800 dark:text-gray-200 text-base"
            >
              <div *ngIf="res.searchResultStatus === loadStatuses.completed">{{ res.searchResult }} - Completed</div>
              <div *ngIf="res.searchResultStatus === loadStatuses.cancelled">{{ res.searchResult }} - Cancelled</div>
              <div *ngIf="res.searchResultStatus === loadStatuses.inProgress">{{ res.searchResult }} - In Progress</div>
              <div
                *ngIf="
                  res.searchResultStatus !== loadStatuses.inProgress &&
                  res.searchResultStatus !== loadStatuses.completed &&
                  res.searchResultStatus !== loadStatuses.cancelled
                "
              >
                {{ res.searchResult }} - Scheduled
              </div>
            </span>
            <ng-template #nonShip class="text-left font-semibold text-gray-800 dark:text-gray-200 text-base">
              {{ res.searchResult }} {{ res.searchResultStatus ? ' - ' + res.searchResultStatus : '' }}
            </ng-template>
            <span class="text-gray-600 dark:text-gray-400 text-sm flex-1 text-right">
              {{ res.type }}
            </span>
          </div>
          <div class="flex flex-row justify-between items-center">
            <div class="text-gray-600 dark:text-gray-400 text-sm flex-1 text-left">
              {{ res.description ? res.description : '' }}
            </div>
            <div class="text-gray-600 dark:text-gray-400 text-sm flex-1 text-right">
              {{ res.timeWindow ? res.timeWindow : '' }}
            </div>
          </div>
        </a>

        <div *ngIf="searchResults.results?.showMore" class="p-2 text-center">
          <button color="accent" mat-button (click)="showMoreLoads()">Show More</button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
